<template>
  <div id="courses-container">
    <div :id="course.uuid" v-for="course in courses" :key="course.uuid" :class="getClass(course)">
      <img class="course-image" :src="course.img_url">
      <h2 class="course-title">{{ course.name  }}</h2>
      <p class="course-dates"><span class="course-start-date">{{ getFinDate(course.course_start) }}</span><span v-if="course.course_start != course.course_end"  class="course-delimeter"> - </span><span v-if="course.course_start != course.course_end" class="course-end-date"> {{ getFinDate(course.course_end) }}</span></p>
      <p class="course-desc">{{ course.description }}</p>
      <a class="course-link" target="_blank" :href="course.link">{{ course.link }}</a>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      courses: []
    }
  },
  methods: {
    getClass(course) {
      return "course-" + course.type + " lang-" + course.lang;
    },
    getFinDate(stamp) {
      const date = new Date(stamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`;
    }
  },
  mounted() {
    axios.get("https://api.adinum.fi/api/courses/search").then((response) => {
      this.courses = response.data;
      console.log(response.data);
    });
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
